import React, { useEffect, useState } from "react";

import SearchSection from "../ProfileSection/SearchSection";
import ModuleCard from "./ModuleCard";
import "./BookPage.css";
import { getAllBooks } from "./api/bookApiCall";

const bookSection = [
  {
    "category": "Investing",
    "topics": [
      {
        "topic": "Stock Market Basics",
        "books": [
          {
            "bookId": 1,
            "title": "The Intelligent Investor",
            "author": "Benjamin Graham",
            "imageUrl": "https://i.postimg.cc/3wdMDNx4/intelligent-investor-icon-1.jpg",
            "summary": "A comprehensive guide on value investing, risk management, and understanding the market.",
            "readLink": "https://example.com/read-the-intelligent-investor",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0060555661",
              "flipkart": "https://www.flipkart.com/intelligent-investor/p/itmf3g7yfmszvp4g"
            }
          },
          {
            "bookId": 2,
            "title": "Common Stocks and Uncommon Profits",
            "author": "Philip A. Fisher",
            "imageUrl": "https://i.postimg.cc/hP1RkNYj/common-stocks-icon.jpg",
            "summary": "A classic book that introduces the qualitative side of stock evaluation and long-term growth.",
            "readLink": "https://example.com/read-common-stocks",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0471445509",
              "flipkart": "https://www.flipkart.com/common-stocks-uncommon-profits/p/itmfbc5z2g6hpsrc"
            }
          },
          {
            "bookId": 3,
            "title": "One Up on Wall Street",
            "author": "Peter Lynch",
            "imageUrl": "https://i.postimg.cc/kggzkG81/one-up-wall-street-icon.jpg",
            "summary": "Peter Lynch's guide on finding growth stocks by investing in what you know and understand.",
            "readLink": "https://example.com/read-one-up-on-wall-street",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0743200403",
              "flipkart": "https://www.flipkart.com/one-up-wall-street/p/itmfbc5zhvrngyzg"
            }
          },
          {
            "bookId": 4,
            "title": "The Little Book of Common Sense Investing",
            "author": "John C. Bogle",
            "imageUrl": "https://i.postimg.cc/qqcWdCjY/little-book-common-sense-icon.jpg",
            "summary": "A compelling case for index investing as a low-cost and efficient investment strategy.",
            "readLink": "https://example.com/read-the-little-book",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/1119404509",
              "flipkart": "https://www.flipkart.com/little-book-common-sense-investing/p/itmfbc5zhvrnd8zk"
            }
          },
          {
            "bookId": 5,
            "title": "Beating the Street",
            "author": "Peter Lynch",
            "imageUrl": "https://i.postimg.cc/CK10HJmK/beating-the-street-icon.jpg",
            "summary": "Peter Lynch shares strategies for stock selection and managing a successful investment portfolio.",
            "readLink": "https://example.com/read-beating-the-street",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0671891634",
              "flipkart": "https://www.flipkart.com/beating-street/p/itmfbc5zhhrhfzgk"
            }
          }
        ]
      }
    ]
  },
  {
    "category": "Personal Finance",
    "topics": [
      {
        "topic": "Financial Independence",
        "books": [
          {
            "bookId": 6,
            "title": "Your Money or Your Life",
            "author": "Vicki Robin & Joe Dominguez",
            "imageUrl": "https://i.postimg.cc/wT0bczPh/your-money-your-life-icon.jpg",
            "summary": "A blueprint for transforming your relationship with money and achieving financial independence.",
            "readLink": "https://example.com/read-your-money-or-your-life",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0143115766",
              "flipkart": "https://www.flipkart.com/your-money-your-life/p/itmfbc5z8htzp9zk"
            }
          },
          {
            "bookId": 7,
            "title": "Rich Dad Poor Dad",
            "author": "Robert Kiyosaki",
            "imageUrl": "https://i.postimg.cc/SRG1PbKb/rich-dad-poor-dad-icon.jpg",
            "summary": "A book about personal finance and wealth-building through assets, entrepreneurship, and investing.",
            "readLink": "https://example.com/read-rich-dad-poor-dad",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/1612680194",
              "flipkart": "https://www.flipkart.com/rich-dad-poor-dad/p/itmfbc5zhxrzgzk"
            }
          },
          {
            "bookId": 8,
            "title": "The Total Money Makeover",
            "author": "Dave Ramsey",
            "imageUrl": "https://i.postimg.cc/28zKZyHk/total-money-makeover-icon.jpg",
            "summary": "A straightforward approach to budgeting, saving, and getting out of debt.",
            "readLink": "https://example.com/read-total-money-makeover",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/1595555277",
              "flipkart": "https://www.flipkart.com/total-money-makeover/p/itmfbc5z8trfgzk"
            }
          },
          {
            "bookId": 9,
            "title": "I Will Teach You To Be Rich",
            "author": "Ramit Sethi",
            "imageUrl": "https://i.postimg.cc/Vk7Q6nmP/i-will-teach-you-rich-icon.jpg",
            "summary": "A practical guide for millennials on budgeting, saving, and building wealth.",
            "readLink": "https://example.com/read-i-will-teach-you-to-be-rich",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/1523505745",
              "flipkart": "https://www.flipkart.com/i-will-teach-you-to-be-rich/p/itmfbc5zr8thgzk"
            }
          },
          {
            "bookId": 10,
            "title": "The Millionaire Next Door",
            "author": "Thomas J. Stanley",
            "imageUrl": "https://i.postimg.cc/Y9WJbPgt/millionaire-next-door-icon.jpg",
            "summary": "Explores the habits and traits of wealthy individuals in America.",
            "readLink": "https://example.com/read-the-millionaire-next-door",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/1589795474",
              "flipkart": "https://www.flipkart.com/millionaire-next-door/p/itmfbc5zhdfhgzk"
            }
          }
        ]
      }
    ]
  },
  {
    "category": "Real Estate",
    "topics": [
      {
        "topic": "Property Investment",
        "books": [
          {
            "bookId": 11,
            "title": "The Millionaire Real Estate Investor",
            "author": "Gary Keller",
            "imageUrl": "https://i.postimg.cc/wMYnPkcL/millionaire-real-estate-investor-icon.jpg",
            "summary": "Strategies for building wealth through real estate investment and management.",
            "readLink": "https://example.com/read-the-millionaire-real-estate-investor",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0071446370",
              "flipkart": "https://www.flipkart.com/millionaire-real-estate-investor/p/itmfbc5zvdfvgzk"
            }
          },
          {
            "bookId": 12,
            "title": "Rich Dad's ABC's of Real Estate Investing",
            "author": "Ken McElroy",
            "imageUrl": "https://i.postimg.cc/KYdWk5Ww/Rich-Dad-s-ABC-s-of-Real-Estate-Investing-by-Ken-Mc-Elroy.jpg",
            "summary": "An approach to real estate investment designed to build passive income and wealth.",
            "readLink": "https://example.com/read-abcs-of-real-estate-investing",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/1937832228",
              "flipkart": "https://www.flipkart.com/rich-dad-abcs-real-estate-investing/p/itmfbc5zdvbrgzk"
            }
          },
          {
            "bookId": 13,
            "title": "Real Estate Investing for Dummies",
            "author": "Eric Tyson & Robert S. Griswold",
            "imageUrl": "https://i.postimg.cc/QCDnSdhq/real-estate-investing-dummies-icon.jpg",
            "summary": "A comprehensive guide to understanding real estate as a path to wealth and security.",
            "readLink": "https://example.com/read-real-estate-investing-for-dummies",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/1119602073",
              "flipkart": "https://www.flipkart.com/real-estate-investing-dummies/p/itmfbc5z8vrfgzk"
            }
          },
          {
            "bookId": 14,
            "title": "The Book on Rental Property Investing",
            "author": "Brandon Turner",
            "imageUrl": "https://i.postimg.cc/fLSh1y4n/book-rental-property-investing-icon.jpg",
            "summary": "Insights on building wealth through rental properties, covering everything from financing to tenant management.",
            "readLink": "https://example.com/read-the-book-on-rental-property-investing",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/099071179X",
              "flipkart": "https://www.flipkart.com/book-rental-property-investing/p/itmfbc5zrdfgzk"
            }
          },
          {
            "bookId": 15,
            "title": "The Real Estate Wholesaling Bible",
            "author": "Than Merrill",
            "imageUrl": "https://i.postimg.cc/SsCvWMFs/real-estate-wholesaling-bible-icon.jpg",
            "summary": "An introduction to real estate wholesaling as a low-risk approach to real estate investment.",
            "readLink": "https://example.com/read-real-estate-wholesaling-bible",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/1118807529",
              "flipkart": "https://www.flipkart.com/real-estate-wholesaling-bible/p/itmfbc5z8vdrgzk"
            }
          }
        ]
      }
    ]
  },
  {
    "category": "Entrepreneurship",
    "topics": [
      {
        "topic": "Building Businesses",
        "books": [
          {
            "bookId": 16,
            "title": "The Lean Startup",
            "author": "Eric Ries",
            "imageUrl": "https://i.postimg.cc/6QSkVK8H/lean-startup-icon.jpg",
            "summary": "Explores agile and iterative development techniques to reduce risk in new ventures.",
            "readLink": "https://example.com/read-the-lean-startup",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0307887898",
              "flipkart": "https://www.flipkart.com/lean-startup/p/itmfbc5zhvfvgzk"
            }
          },
          {
            "bookId": 17,
            "title": "The E-Myth Revisited",
            "author": "Michael E. Gerber",
            "imageUrl": "https://i.postimg.cc/sxpCDLDT/e-myth-revisited-icon.jpg",
            "summary": "An in-depth look at building a successful small business.",
            "readLink": "https://example.com/read-the-e-myth-revisited",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0887307280",
              "flipkart": "https://www.flipkart.com/e-myth-revisited/p/itmfbc5z8hrvgzk"
            }
          },
          {
            "bookId": 18,
            "title": "Zero to One",
            "author": "Peter Thiel & Blake Masters",
            "imageUrl": "https://i.postimg.cc/NFfT439n/zero-to-one-icon.webp",
            "summary": "Insights on building innovative, technology-focused startups.",
            "readLink": "https://example.com/read-zero-to-one",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0804139296",
              "flipkart": "https://www.flipkart.com/zero-to-one/p/itmfbc5zrvrgzk"
            }
          },
          {
            "bookId": 19,
            "title": "Good to Great",
            "author": "Jim Collins",
            "imageUrl": "https://i.postimg.cc/T32vfC8c/good-to-great-icon.jpg",
            "summary": "A deep dive into why some companies transition from mediocre to exceptional.",
            "readLink": "https://example.com/read-good-to-great",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0066620996",
              "flipkart": "https://www.flipkart.com/good-to-great/p/itmfbc5z8vvrgzk"
            }
          },
          {
            "bookId": 20,
            "title": "Crushing It!",
            "author": "Gary Vaynerchuk",
            "imageUrl": "https://i.postimg.cc/qqfdrr0t/crushing-it-icon.jpg",
            "summary": "Gary Vee shares insights on building a personal brand in the digital age.",
            "readLink": "https://example.com/read-crushing-it",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0062674676",
              "flipkart": "https://www.flipkart.com/crushing-it/p/itmfbc5zhtrgzk"
            }
          }
        ]
      }
    ]
  },
  {
    "category": "Economics",
    "topics": [
      {
        "topic": "Economic Principles",
        "books": [
          {
            "bookId": 21,
            "title": "Basic Economics",
            "author": "Thomas Sowell",
            "imageUrl": "https://i.postimg.cc/NjQBLQXm/basic-economics-icon.jpg",
            "summary": "An introductory text that explains economic principles in a clear, accessible way.",
            "readLink": "https://example.com/read-basic-economics",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0465060730",
              "flipkart": "https://www.flipkart.com/basic-economics/p/itmfbc5zhtrfgzk"
            }
          },
          {
            "bookId": 22,
            "title": "Capital in the Twenty-First Century",
            "author": "Thomas Piketty",
            "imageUrl": "https://i.postimg.cc/VNV87MVW/capital-21st-century-icon.jpg",
            "summary": "Explores the history of wealth inequality and its implications for the future.",
            "readLink": "https://example.com/read-capital-in-the-21st-century",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/067443000X",
              "flipkart": "https://www.flipkart.com/capital-in-the-21st-century/p/itmfbc5zdfgzk"
            }
          },
          {
            "bookId": 23,
            "title": "Freakonomics",
            "author": "Steven D. Levitt & Stephen J. Dubner",
            "imageUrl": "https://i.postimg.cc/44tg5Ybp/freakonomics-icon.jpg",
            "summary": "A fresh take on understanding economic and social forces through unconventional questions.",
            "readLink": "https://example.com/read-freakonomics",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0060731338",
              "flipkart": "https://www.flipkart.com/freakonomics/p/itmfbc5zhhrfgzk"
            }
          },
          {
            "bookId": 24,
            "title": "The Wealth of Nations",
            "author": "Adam Smith",
            "imageUrl": "https://i.postimg.cc/52yKsGdD/wealth-of-nations-icon.jpg",
            "summary": "The foundational text in classical economics, addressing free markets, division of labor, and economic policy.",
            "readLink": "https://example.com/read-the-wealth-of-nations",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/161382348X",
              "flipkart": "https://www.flipkart.com/wealth-of-nations/p/itmfbc5z8thzgzk"
            }
          },
          {
            "bookId": 25,
            "title": "Misbehaving: The Making of Behavioral Economics",
            "author": "Richard H. Thaler",
            "imageUrl": "https://i.postimg.cc/zDTsh98j/misbehaving-icon.jpg",
            "summary": "An account of the development of behavioral economics and the role of psychology in economics.",
            "readLink": "https://example.com/read-misbehaving",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/039335279X",
              "flipkart": "https://www.flipkart.com/misbehaving/p/itmfbc5z8fvrzgzk"
            }
          }
        ]
      }
    ]
  },
  {
    "category": "Behavioral Finance",
    "topics": [
      {
        "topic": "Investor Psychology",
        "books": [
          {
            "bookId": 26,
            "title": "Thinking, Fast and Slow",
            "author": "Daniel Kahneman",
            "imageUrl": "https://i.postimg.cc/dQRf3rjN/thinking-fast-slow-icon.jpg",
            "summary": "Examines the two systems that drive human thinking and influence decision-making in investing.",
            "readLink": "https://example.com/read-thinking-fast-and-slow",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0374533555",
              "flipkart": "https://www.flipkart.com/thinking-fast-and-slow/p/itmfbc5zfgzk"
            }
          },
          {
            "bookId": 27,
            "title": "The Psychology of Money",
            "author": "Morgan Housel",
            "imageUrl": "https://i.postimg.cc/YCDZk7zp/psychology-of-money-icon.jpg",
            "summary": "Insights on how people think about money, risk, and decision-making.",
            "readLink": "https://example.com/read-the-psychology-of-money",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0857197681",
              "flipkart": "https://www.flipkart.com/the-psychology-of-money/p/itmfbc5zfhtrgzk"
            }
          },
          {
            "bookId": 28,
            "title": "Predictably Irrational",
            "author": "Dan Ariely",
            "imageUrl": "https://i.postimg.cc/Pr403YLv/predictably-irrational-icon.jpg",
            "summary": "A deep dive into the irrational forces that influence decision-making.",
            "readLink": "https://example.com/read-predictably-irrational",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0061353248",
              "flipkart": "https://www.flipkart.com/predictably-irrational/p/itmfbc5zrthgzk"
            }
          },
          {
            "bookId": 29,
            "title": "Nudge",
            "author": "Richard H. Thaler & Cass R. Sunstein",
            "imageUrl": "https://i.postimg.cc/3J7PFnwH/nudge-icon.jpg",
            "summary": "A look at how subtle changes in choice architecture can significantly impact decisions.",
            "readLink": "https://example.com/read-nudge",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/014311526X",
              "flipkart": "https://www.flipkart.com/nudge/p/itmfbc5zhthgzk"
            }
          },
          {
            "bookId": 30,
            "title": "Animal Spirits",
            "author": "George A. Akerlof & Robert J. Shiller",
            "imageUrl": "https://i.postimg.cc/y8nBZ2h5/animal-spirits-ico.jpg",
            "summary": "An exploration of the psychological factors that drive the economy.",
            "readLink": "https://example.com/read-animal-spirits",
            "purchaseLinks": {
              "amazon": "https://www.amazon.com/dp/0691142335",
              "flipkart": "https://www.flipkart.com/animal-spirits/p/itmfbc5zvhtrgzk"
            }
          }
        ]
      }
    ]
  }
]

function BooksPage() {
  const [modulesData, setModulesData] = useState(null);
  useEffect(() => {
    getArticlesHandler();
  }, []);
  const getArticlesHandler = async () => {
    try {
      const responseData = await getAllBooks();
      setModulesData(responseData);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(modulesData,'modulesData')
  return (
    <>
      <div className="full--container">
        <div className="main--section">
          <div className="full-top-section">
            <div className="calc--search">
              <SearchSection />
            </div>
          </div>
          <div className="inner--container">
            <div className="w-1211">
              {bookSection?.map((module, index) => {
                return (
                  <div key={module.id} className="card--col-5">
                    <span className="module--title">{module.category}</span>
                    <div className="card--module mb-60">
                      {module.topics.map((topic, ind) => {
                        return (
                          <div key={topic.id}>
                            <span className="module--topic">{topic.topic}</span>
                            <div className="slide--book">
                              {topic.books.map((book) => {
                                return (
                                  <ModuleCard
                                    image={book.imageUrl}
                                    alt="banner"
                                    title={book.title}
                                    author={book.author}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BooksPage;
