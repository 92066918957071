import React from "react";
import "./ArticalCard.css";
import moment from "moment";

const ArticalCard = ({
  image,
  cardTitle,
  writerName,
  bottomSection,
  timeSection,
  videoSection,
  ...props
}) => {
  return (
    <>
      <div className="card--ci">
        <div className="card--banner">
          <img src={image} className="cd--bnnr-img" />
        </div>
        <div className="card--sm-vr">
          <div className="card--title">
            <span>{cardTitle}</span>
          </div>
          <div className="card--writer-name">
            <span>{writerName}</span>
          </div>
          <div className="card--time-section flex--sec">
            <span>{bottomSection}</span>
            <time>{moment(timeSection).format('DD/MM/YYYY')}</time>
          </div>
        </div>
        {videoSection && (
          <div className="slide--effect">
            <button type="button" className="btn--video">
              <img
                src={videoSection}
                alt="polygon-icon"
                width={64}
                height={64}
                className="innr--arr"
              />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ArticalCard;
