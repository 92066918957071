import React, { useState } from "react";
import InputField from "../InputSection/InputField";
import { useNavigate } from "react-router-dom";
import showToast from "../common/toast/ToastHelper";
import { resetPassword } from "./api/authApiCall";

function ForgetPage() {
  const navigate = useNavigate();
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const onChangeHandler = (name, value) => {
    setState({ ...state, [name]: value });
    console.log(state);
  };
  const gotoLoginPageHandler = () => {
    navigate("/login", {
      state: {
        type: "loginPage",
      },
    });
  };
  const onSubmitHandler = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const loginData = localStorage.getItem("userCredential");
      const data = JSON.parse(loginData);
      const param = {
        resetToken: data.token,
        newPassword: state.newPassword,
      };
      const responseData = await resetPassword(param);
      console.log(loginData, "loginData");

      navigate("/home", { replace: true });
      setIsLoading(false);
      setIsError(false);
      showToast("success", "Password Successfully changed");
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMsg(error.message);
      showToast("error", error.message);

      console.log(error, "error");
    }
  };
  return (
    <>
      <h1 className="form--title mb-20">Change Password</h1>

      <div className="mb-120">
        <InputField
          onChangeHandler={(name, value) => {
            onChangeHandler(name, value);
          }}
          name={"newPassword"}
          value={state["newPassword"] || ""}
          label={"New Password"}
          inputType={"password"}
          placeholder={"Enter new Password"}
        />
      </div>
      <div
        onClick={() => {
          onSubmitHandler();
        }}
        className="form--submit flex--gap"
      >
        <button type="button" className="btn--submit">
          Submit
        </button>
      </div>
    </>
  );
}

export default ForgetPage;
