import { Navigate } from "react-router-dom";
import LoginSection from "../../LoginPage/LoginSection";
import CagrcCalculator from "../../ProfileSection/CagrcCalculator";
import MyProfile from "../../ProfileSection/MyProfile";
import SipCalculator from "../../ProfileSection/SipCalculator";
import HomePage from "../../HomePage/HomePage";
import HomePage2 from "../../HomePage2/HomePage2";
import Artical from "../../SliderInput/ArticalSection/Artical";
import LearnPage from "../../LearnPage/LearnPage";
import QuizHistory from "../../QuizSection/QuizHistory";
import BooksPage from "../../BookPage/BookPage";
import AboutUs from "../../AboutUs/AboutUs";
import ContactUs from "../../ContactUs/ContactUs";
import PresentValueCalculator from "../../ProfileSection/PresentValueCalculator";
import FutureValueCalculator from "../../ProfileSection/FutureValueCalculator";
import LearnModules from "../../LearnPage/LearnModules/LearnModules";
import TermsConditions from "../../termAndCondition/TermConditions";
import FAQ from "../../fAq/FAQ";
import FeedbackSection from "../../feedbackSection/FeedbackSection";
import ArticleDetail from "../../SliderInput/ArticalSection/articleDetail/ArticleDetail";

export const routes = [
  {
    isHeaderShown: false,
    isProtected: false,
    path: "/login",
    element: <LoginSection />,
  },
  {
    isHeaderShown: false,
    isProtected: false,
    path: "/createAccount",
    element: <LoginSection />,
  },
  {
    isHeaderShown: false,
    isProtected: false,
    path: "/forgetPage",
    element: <LoginSection />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/changePassword",
    element: <LoginSection />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/termsConditions",
    element: <TermsConditions />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/feedbackSection",
    element: <FeedbackSection />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/myProfile",
    element: <MyProfile />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/fAq",
    element: <FAQ />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/sipcalculator",
    element: <SipCalculator />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/home",
    element: <HomePage />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/HomePage2",
    element: <HomePage2 />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/article",
    element: <Artical />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/article/:id",
    element: <ArticleDetail />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/learn",
    element: <LearnPage />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/learn/:moduleId",
    element: <LearnModules />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/learn",
    element: <LearnPage />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/quiz",
    element: <QuizHistory />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/books",
    element: <BooksPage />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/about",
    element: <AboutUs />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/contact",
    element: <ContactUs />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/sip",
    element: <SipCalculator />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/cagrc",
    element: <CagrcCalculator />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/presentValue",
    element: <PresentValueCalculator />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/futureValue",
    element: <FutureValueCalculator />,
  },
  {
    isHeaderShown: true,
    isProtected: false,
    path: "/cgrcalculator",
    element: <CagrcCalculator />,
  },
];
