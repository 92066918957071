import React from "react";
import PointerSection from "../PointerSection/PointerSection";
import FeedbackIcon from "../assets/image/Feedback/feedback-icon.svg";
import SuggestionIcon from "../assets/image/Feedback/suggestion-icon.svg";
import "./FeedbackSection.css";

function FeedbackSection() {
  return (
    <div className="feedback--container">
      <div className="feedback--header">
        <PointerSection
          title="Feedback & Suggestions"
          colorText="text--black"
        />
        <h2 className="feedback--title">Your Thoughts Drive Our Excellence</h2>
        <p className="feedback--description">
          At Finyogi, we’re committed to delivering top-notch services that
          empower you to stay ahead in the market. Your feedback is invaluable
          in helping us refine our platform and provide you with the best
          experience possible. Share your ideas and help shape the future of
          Finyogi!
        </p>
      </div>

      <div className="feedback--content">
        <div className="feedback--item">
          <img
            src={FeedbackIcon}
            alt="Feedback Icon"
            className="feedback--icon"
          />
          <h3 className="feedback--item-title">Share Your Feedback</h3>
          <p className="feedback--item-description">
            Have thoughts on how we can improve? Let us know what’s on your
            mind!
          </p>
        </div>
        <div className="feedback--item">
          <img
            src={SuggestionIcon}
            alt="Suggestion Icon"
            className="feedback--icon"
          />
          <h3 className="feedback--item-title">Suggest New Features</h3>
          <p className="feedback--item-description">
            Got ideas for tools or features you'd like to see? We’d love to hear
            them.
          </p>
        </div>
      </div>

      <div className="feedback--cta">
        <button type="button" className="btn--submit-feedback">
          Share Your Feedback Today!
        </button>
      </div>
    </div>
  );
}

export default FeedbackSection;
