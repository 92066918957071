import React, { useState } from "react";
import InputField from "../InputSection/InputField";
import { useNavigate } from "react-router-dom";
import showToast from "../common/toast/ToastHelper";
import { forgetPassword } from "./api/authApiCall";

function ForgetPage() {
  const navigate = useNavigate();
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const onChangeHandler = (name, value) => {
    setState({ ...state, [name]: value });
    console.log(state);
  };
  const gotoLoginPageHandler = () => {
    navigate("/login", {
      state: {
        type: "loginPage",
      },
    });
  };
  const onSubmitHandler = async () => {
    try {
      const param = {
        email: state.email,
      };
      setIsLoading(true);
      setIsError(false);
      const loginData = await forgetPassword(param);
      console.log(loginData, "loginData");
      localStorage.setItem("userCredential", JSON.stringify(loginData));
      navigate("/home", { replace: true });
      setIsLoading(false);
      setIsError(false);
      showToast("success", "Email Successfully sent");
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMsg(error.message);
      showToast("error", error.message);

      console.log(error, "error");
    }
  };
  return (
    <>
      <h1 className="form--title mb-20">Forgot Password</h1>
      <span className="form--subtitle mb-95">
        Enter your login email details below
      </span>
      <div className="mb-120">
        <InputField
          onChangeHandler={(name, value) => {
            onChangeHandler(name, value);
          }}
          name={"email"}
          value={state["email"] || ""}
          label={"Email"}
          inputType={"email"}
          placeholder={"example@gmail.com"}
        />
      </div>
      <div className="form--submit flex--gap">
        <button  onClick={() => {
            onSubmitHandler();
          }} type="button" className="btn--submit">
          Submit
        </button>
        <button
          type="button"
          className="btn--second"
          onClick={() => {
            gotoLoginPageHandler();
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export default ForgetPage;
