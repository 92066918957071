import React, { useState } from 'react'
import InputField from '../InputSection/InputField'
import './CreatePassword.css'
import { useNavigate } from 'react-router-dom';
import { forgetPassword } from './api/authApiCall';
import showToast from '../common/toast/ToastHelper';

function CreatePassword() {
    const navigate = useNavigate();
    const [state, setState] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const onSubmitHandler = async () => {
        try {
          const param = {
            email: state.email,
            password: state.password,
          };
          setIsLoading(true);
          setIsError(false);
          const loginData = await forgetPassword(param);
          console.log(loginData, "loginData");
          localStorage.setItem("userCredential", JSON.stringify(loginData));
          navigate("/home", { replace: true });
          setIsLoading(false);
          setIsError(false);
          showToast("success", "Successfully login");
        } catch (error) {
          setIsLoading(false);
          setIsError(true);
          setErrorMsg(error.message);
          showToast("error", error.message);
    
          console.log(error, "error");
        }
      };
  return (
    <>
      <h1 className="form--title mb-20">Create Password</h1>
      <span className="form--subtitle mb-57">
        We’ll ask this password whenever you sign in.
      </span>
      <div className="form--wrapper">
        <InputField
          label={"Password"}
          inputType={"Password"}
          placeholder={"New password"}
        />
        <InputField
          label={"Confirm password"}
          inputType={"Password"}
          placeholder={"Confirm password"}
        />
      </div>
      <div className="form--submit flex--gap">
        <button onClick={() => {
            onSubmitHandler();
          }} 
          type="button" className="btn--submit">
          Update
        </button>
      </div>
    </>
  );
}

export default CreatePassword