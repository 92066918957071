import React, { useState } from "react";
import "./FAQ.css";

const faqData = [
  {
    question: "What is Finyogi?",
    answer:
      "Finyogi is a financial platform that provides insights, tools, and resources to help users make informed decisions regarding personal finance, investments, and market trends.",
  },
  {
    question: "How do I create an account on Finyogi?",
    answer:
      'To create an account, click on the "Sign Up" button on the homepage, fill in your details, and follow the instructions to verify your account.',
  },
  {
    question: "Is my personal information safe with Finyogi?",
    answer:
      "Yes, Finyogi uses advanced security protocols and encryption to ensure your personal information remains safe and secure.",
  },
  {
    question: "What services does Finyogi offer?",
    answer:
      "Finyogi offers financial tools, market insights, educational resources, and personalized investment recommendations.",
  },
  {
    question: "Is Finyogi free to use?",
    answer:
      "Yes, Finyogi offers a free version with essential tools. However, we also provide premium subscriptions for advanced features and insights.",
  },
  {
    question: "How can I contact Finyogi support?",
    answer:
      "You can reach our support team via email at support@finyogi.com or call us at +91-XXXXXXXXXX.",
  },
];

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-header">
        <h1>Frequently Asked Questions</h1>
        <p>Find answers to the most common questions about Finyogi.</p>
      </div>

      <div className="faq-content">
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <button
              className={`faq-question ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <span className="faq-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </button>
            <div
              className={`faq-answer ${activeIndex === index ? "show" : ""}`}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
