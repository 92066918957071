import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './FutureValueCalculator.css';

function FutureValueCalculator() {
  const [presentValue, setPresentValue] = useState(100000); // Present value in ₹
  const [years, setYears] = useState(10); // Number of years
  const [rateOfReturn, setRateOfReturn] = useState(8); // Annual rate of return in %

  // Calculate Future Value
  const calculateFutureValue = () => {
    const futureValue = (
      presentValue * Math.pow(1 + rateOfReturn / 100, years)
    ).toFixed(2);
    return isNaN(futureValue) ? 0 : futureValue;
  };

  const futureValue = calculateFutureValue();

  // Line Chart Data
  const generateChartData = () => {
    const labels = Array.from({ length: years + 1 }, (_, i) => i); // Years 0 to 'years'
    const data = labels.map(
      (year) => presentValue * Math.pow(1 + rateOfReturn / 100, year)
    );

    return {
      labels,
      datasets: [
        {
          label: 'Future Value (₹)',
          data,
          borderColor: '#0030ad',
          backgroundColor: 'rgba(0, 48, 173, 0.2)',
          pointBackgroundColor: '#0030ad',
          tension: 0.4,
        },
      ],
    };
  };

  return (
    <div className="fv--calculator-container">
      <h1 className="fv--title">Future Value Calculator</h1>
      <p className="fv--subtitle">
        Predict the future value of an investment for a given period and rate of return.
      </p>
      <div className="fv--content">
        {/* Input Section */}
        <div className="fv--inputs">
          <div className="input-group">
            <label>Present Value of Investment (₹)</label>
            <input
              type="number"
              value={presentValue}
              onChange={(e) => setPresentValue(Number(e.target.value))}
              min="1"
            />
            <input
              type="range"
              value={presentValue}
              onChange={(e) => setPresentValue(Number(e.target.value))}
              min="1"
              max="10000000"
            />
          </div>
          <div className="input-group">
            <label>Number of Years</label>
            <input
              type="number"
              value={years}
              onChange={(e) => setYears(Number(e.target.value))}
              min="1"
            />
            <input
              type="range"
              value={years}
              onChange={(e) => setYears(Number(e.target.value))}
              min="1"
              max="50"
            />
          </div>
          <div className="input-group">
            <label>Expected Annual Rate of Return (%)</label>
            <input
              type="number"
              value={rateOfReturn}
              onChange={(e) => setRateOfReturn(Number(e.target.value))}
              min="0"
              step="0.1"
            />
            <input
              type="range"
              value={rateOfReturn}
              onChange={(e) => setRateOfReturn(Number(e.target.value))}
              min="0"
              max="50"
            />
          </div>
          <div className="fv--results">
            <h2>Calculated Future Value</h2>
            <p>
              ₹{presentValue} invested today at {rateOfReturn}% for {years} year(s) will grow to
              <strong> ₹{futureValue}</strong>.
            </p>
          </div>
        </div>
        {/* Graph Section */}
        <div className="fv--chart">
          <h2>Growth Over Time</h2>
          <Line data={generateChartData()} />
        </div>
      </div>
    </div>
  );
}

export default FutureValueCalculator;
