import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import './PresentValueCalculator.css';

function PresentValueCalculator() {
  const [futureValue, setFutureValue] = useState(1000000); // Future value in ₹
  const [years, setYears] = useState(10); // Investment duration in years
  const [inflationRate, setInflationRate] = useState(5); // Annual inflation rate in %

  // Calculate Present Value
  const calculatePresentValue = () => {
    const presentValue = (
      futureValue / Math.pow(1 + inflationRate / 100, years)
    ).toFixed(2);
    return isNaN(presentValue) ? 0 : presentValue;
  };

  const presentValue = calculatePresentValue();

  // Bar Chart Data
  const barData = {
    labels: ['Future Value', 'Present Value'],
    datasets: [
      {
        label: 'Value in ₹',
        data: [futureValue, presentValue],
        backgroundColor: ['#0030ad', '#7fbaed'],
        hoverBackgroundColor: ['#00227b', '#5aa3d6'],
      },
    ],
  };

  return (
    <div className="pv--calculator-container">
      <h1 className="pv--title">Present Value Calculator</h1>
      <p className="pv--subtitle">
        Find out the current value of an asset or an investment for a given period, accounting for inflation.
      </p>
      <div className="pv--content">
        {/* Input Section */}
        <div className="pv--inputs">
          <div className="input-group">
            <label>Future Value of Investment (₹)</label>
            <input
              type="number"
              value={futureValue}
              onChange={(e) => setFutureValue(Number(e.target.value))}
              min="1"
            />
            <input
              type="range"
              value={futureValue}
              onChange={(e) => setFutureValue(Number(e.target.value))}
              min="1"
              max="10000000"
            />
          </div>
          <div className="input-group">
            <label>Number of Years</label>
            <input
              type="number"
              value={years}
              onChange={(e) => setYears(Number(e.target.value))}
              min="1"
            />
            <input
              type="range"
              value={years}
              onChange={(e) => setYears(Number(e.target.value))}
              min="1"
              max="50"
            />
          </div>
          <div className="input-group">
            <label>Expected Annual Inflation Rate (%)</label>
            <input
              type="number"
              value={inflationRate}
              onChange={(e) => setInflationRate(Number(e.target.value))}
              min="0"
              step="0.1"
            />
            <input
              type="range"
              value={inflationRate}
              onChange={(e) => setInflationRate(Number(e.target.value))}
              min="0"
              max="50"
            />
          </div>
          <div className="pv--results">
            <h2>Calculated Present Value</h2>
            <p>
              A future value of ₹{futureValue} in {years} year(s), with an annual inflation rate of {inflationRate}%, 
              is worth <strong>₹{presentValue}</strong> today.
            </p>
          </div>
        </div>
        {/* Graph Section */}
        <div className="pv--chart">
          <h2>Value Comparison</h2>
          <Bar data={barData} />
        </div>
      </div>
    </div>
  );
}

export default PresentValueCalculator;
