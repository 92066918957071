import Configs from "../api/Configs";
const buildUrl = (path) => {
  return Configs.BASE_URL + path;
};
export const Login_Url = buildUrl("/auth/login");
export const Logout_Url = buildUrl("/auth/logout");
export const Signup_Url = buildUrl("/auth/signup");
export const Reset_Password_Url = buildUrl("/auth/reset-password");
export const Forgot_Password_Url = buildUrl("/auth/forgot-password");
export const Articles_Url = buildUrl("/articles");
export const Learn_Modules_Url = buildUrl("/learn-modules");
export const Books_Url = buildUrl("/books");
