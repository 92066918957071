import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import './CagrcCalculator.css';

function CagrcCalculator() {
  const [initialInvestment, setInitialInvestment] = useState(1000);
  const [finalValue, setFinalValue] = useState(5000);
  const [investmentPeriod, setInvestmentPeriod] = useState(5);

  // Calculate CAGR
  const calculateCAGR = () => {
    const cagr = (
      ((Math.pow(finalValue / initialInvestment, 1 / investmentPeriod) - 1) * 100)
    ).toFixed(2);
    return isNaN(cagr) ? 0 : cagr;
  };

  const cagr = calculateCAGR();

  // Pie Chart Data
  const pieData = {
    labels: ['Initial Investment', 'Growth'],
    datasets: [
      {
        data: [initialInvestment, finalValue - initialInvestment],
        backgroundColor: ['#0030ad', '#7fbaed'],
        hoverBackgroundColor: ['#00227b', '#5aa3d6'],
      },
    ],
  };

  return (
    <div className="cagr--calculator-container">
      <h1 className="cagr--title">CAGR Calculator</h1>
      <p className="cagr--subtitle">
        Calculate the Compound Annual Growth Rate (CAGR) of your investment.
      </p>
      <div className="cagr--content">
        {/* Input Section */}
        <div className="cagr--inputs">
          <div className="input-group">
            <label>Initial Investment (₹)</label>
            <input
              type="number"
              value={initialInvestment}
              onChange={(e) => setInitialInvestment(Number(e.target.value))}
              min="1"
            />
            <input
              type="range"
              value={initialInvestment}
              onChange={(e) => setInitialInvestment(Number(e.target.value))}
              min="1"
              max="1000000"
            />
          </div>
          <div className="input-group">
            <label>Final Value of Investment (₹)</label>
            <input
              type="number"
              value={finalValue}
              onChange={(e) => setFinalValue(Number(e.target.value))}
              min={initialInvestment + 1}
            />
            <input
              type="range"
              value={finalValue}
              onChange={(e) => setFinalValue(Number(e.target.value))}
              min={initialInvestment + 1}
              max="1000000"
            />
          </div>
          <div className="input-group">
            <label>Investment Period (Years)</label>
            <input
              type="number"
              value={investmentPeriod}
              onChange={(e) => setInvestmentPeriod(Number(e.target.value))}
              min="1"
            />
            <input
              type="range"
              value={investmentPeriod}
              onChange={(e) => setInvestmentPeriod(Number(e.target.value))}
              min="1"
              max="50"
            />
          </div>
          <div className="cagr--results">
            <h2>Your CAGR Result</h2>
            <p>
              Your investment grew from ₹{initialInvestment} to ₹{finalValue} in {investmentPeriod} year(s),
              at a compound annual growth rate of <strong>{cagr}%</strong>.
            </p>
          </div>
        </div>
        {/* Graph Section */}
        <div className="cagr--chart">
          <h2>Investment Breakdown</h2>
          <Pie data={pieData} />
        </div>
      </div>
    </div>
  );
}

export default CagrcCalculator;
