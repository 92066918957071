const qa = {
  ENV: "qa",
  BASE_URL: "http://localhost:5000/api",
  CHAT_URL: "",
  DOMAIN_URL: "https://learn.finyogi.com",
};

const prod = {
  ENV: "prod",
  BASE_URL: "https://finyogi-service-n8q4.onrender.com/api",
  DOMAIN_URL: "https://learn.finyogi.com",
};

const config = prod;

/**
 * Return base url.
 */
export const BaseUrl = () => {
  return config.BASE_URL;
};

export default {
  ...config,
};

/**
 * Get Headers
 */
export const getHeaders = () => {
  return {
    "Content-Type": "application/json",
  };
};
