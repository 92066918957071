import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import './SipCalculator.css';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

function SipCalculator() {
  const [monthlyInvestment, setMonthlyInvestment] = useState(5000);
  const [investmentYears, setInvestmentYears] = useState(10);
  const [expectedReturnRate, setExpectedReturnRate] = useState(12);

  const calculateSIP = () => {
    const monthlyRate = expectedReturnRate / 12 / 100;
    const totalMonths = investmentYears * 12;
    const futureValue =
      monthlyInvestment *
      (((1 + monthlyRate) ** totalMonths - 1) / monthlyRate) *
      (1 + monthlyRate);
    return futureValue.toFixed(2);
  };

  const futureValue = calculateSIP();
  const totalInvestment = (monthlyInvestment * investmentYears * 12).toFixed(2);
  const capitalAppreciation = (futureValue - totalInvestment).toFixed(2);

  const pieData = {
    labels: ['Total Investment', 'Capital Appreciation'],
    datasets: [
      {
        data: [totalInvestment, capitalAppreciation],
        backgroundColor: ['#0030ad', '#42a5f5'],
        hoverBackgroundColor: ['#00227b', '#2196f3'],
      },
    ],
  };

  return (
    <div className="sip--calculator-container">
      <h1 className="sip--title">SIP Calculator</h1>
      <p className="sip--subtitle">
        Explore the future value of your Systematic Investment Plan (SIP)
      </p>

      <div className="sip--content">
        {/* Input and Sliders Section */}
        <div className="sip--inputs">
          <div className="input-group">
            <label htmlFor="monthlyInvestment">
              How much would you like to invest every month? (₹)
            </label>
            <input
              type="number"
              id="monthlyInvestment"
              min="500"
              max="100000"
              step="500"
              value={monthlyInvestment}
              onChange={(e) =>
                setMonthlyInvestment(Math.min(Math.max(Number(e.target.value), 500), 100000))
              }
            />
            <input
              type="range"
              min="500"
              max="100000"
              step="500"
              value={monthlyInvestment}
              onChange={(e) => setMonthlyInvestment(Number(e.target.value))}
            />
          </div>

          <div className="input-group">
            <label htmlFor="investmentYears">
              Number of years for which you want to invest?
            </label>
            <input
              type="number"
              id="investmentYears"
              min="1"
              max="30"
              value={investmentYears}
              onChange={(e) =>
                setInvestmentYears(Math.min(Math.max(Number(e.target.value), 1), 30))
              }
            />
            <input
              type="range"
              min="1"
              max="30"
              step="1"
              value={investmentYears}
              onChange={(e) => setInvestmentYears(Number(e.target.value))}
            />
          </div>

          <div className="input-group">
            <label htmlFor="expectedReturnRate">
              Expected annual rate of return? (%)
            </label>
            <input
              type="number"
              id="expectedReturnRate"
              min="1"
              max="20"
              step="0.1"
              value={expectedReturnRate}
              onChange={(e) =>
                setExpectedReturnRate(Math.min(Math.max(Number(e.target.value), 1), 20))
              }
            />
            <input
              type="range"
              min="1"
              max="20"
              step="0.1"
              value={expectedReturnRate}
              onChange={(e) => setExpectedReturnRate(Number(e.target.value))}
            />
          </div>

          {/* Calculation Results */}
          <div className="sip--results">
            <h2>Calculation Results</h2>
            <p>
              <strong>Future Value: </strong>₹ {futureValue}
            </p>
            <p>
              <strong>Total Investment: </strong>₹ {totalInvestment}
            </p>
            <p>
              <strong>Capital Appreciation: </strong>₹ {capitalAppreciation}
            </p>
          </div>
        </div>

        {/* Pie Chart Section */}
        <div className="sip--chart">
          <h2>Investment Breakdown</h2>
          <Pie data={pieData} />
        </div>
      </div>
    </div>
  );
}

export default SipCalculator;
