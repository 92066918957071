import React from "react";
import "./TermsConditions.css";
import moment from "moment";

function TermsConditions() {
  const currentDate = moment().format("DD MMMM YYYY");
  return (
    <div className="terms-container">
      <div className="terms-header">
        <h1>Terms and Conditions</h1>
        <p className="terms-date">
          Effective Date: <strong>{currentDate}</strong>
        </p>
      </div>

      <div className="terms-content">
        <section>
          <h2>1. Definitions</h2>
          <p>
            <strong>Finyogi:</strong> Refers to the website, services, and
            resources provided under this domain.
            <strong>User:</strong> Refers to any person who accesses, browses,
            or uses the Website.
            <strong>Content:</strong> Refers to all text, images, graphics,
            data, and other information provided through the Website.
          </p>
        </section>

        <section>
          <h2>2. Use of Website</h2>
          <p>
            You agree not to use the Website for illegal, fraudulent, or harmful
            activities. Permitted use is restricted to personal and
            non-commercial purposes.
          </p>
          <ul>
            <li>
              Do not distribute, copy, or modify the Content without written
              permission.
            </li>
            <li>
              Do not interfere with the functionality of the Website or attempt
              to breach security protocols.
            </li>
          </ul>
        </section>

        <section>
          <h2>3. Intellectual Property Rights</h2>
          <p>
            All content, designs, and trademarks belong to Finyogi or its
            licensors and are protected by law. You are granted limited access
            for personal use only.
          </p>
        </section>

        <section>
          <h2>4. User-Generated Content</h2>
          <p>
            Any content you submit to the Website (comments, reviews) grants us
            a license to display and distribute it. Ensure your submissions are
            not offensive or unlawful.
          </p>
        </section>

        <section>
          <h2>5. Third-Party Links</h2>
          <p>
            Links to third-party websites are for convenience only. We are not
            responsible for their content or policies. Access them at your own
            risk.
          </p>
        </section>

        <section>
          <h2>6. Limitation of Liability</h2>
          <p>
            Finyogi is not liable for any damages, including loss of data,
            arising from the use of the Website. We do not guarantee
            uninterrupted or error-free access.
          </p>
        </section>

        <section>
          <h2>7. Privacy Policy</h2>
          <p>
            Your use of the Website is also governed by our Privacy Policy.
            Please review it to understand how we handle your data.
          </p>
        </section>

        <section>
          <h2>8. Indemnification</h2>
          <p>
            You agree to indemnify Finyogi and its affiliates from any claims
            arising due to your violation of these Terms or misuse of the
            Website.
          </p>
        </section>

        <section>
          <h2>9. Modifications to Terms</h2>
          <p>
            We reserve the right to modify these Terms. Changes will take effect
            immediately upon posting. Continued use of the Website constitutes
            acceptance of the new Terms.
          </p>
        </section>

        <section>
          <h2>10. Governing Law</h2>
          <p>
            These Terms are governed by the laws of India. Disputes will be
            resolved in the jurisdiction of [Your Location].
          </p>
        </section>

        <section>
          <h2>11. Contact Information</h2>
          <p>
            If you have questions regarding these Terms, please contact us at:
          </p>
          <ul>
            <li>
              <strong>Email:</strong> support@finyogi.com
            </li>
            <li>
              <strong>Phone:</strong> +91 [Insert Contact Number]
            </li>
            <li>
              <strong>Address:</strong> King Street, Prior Lake, New York
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}

export default TermsConditions;
